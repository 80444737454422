
riot.tag2('datepicker', '<div class="monthpicker"> <button onclick="{prevmonth}" class="prev">prev</button> <div><span class="month"> {months[month]} <select name="monthinput" value="{month}" onchange="{setMonth}" class="month"> <option each="{month,index in months}" value="{index}">{month} </option> </select></span><span class="year"> {year} <select name="yearinput" value="{year}" onchange="{setYear}" class="year"> <option each="{year,index in years}" value="{year}">{year}</option> </select></span></div> <button onclick="{nextmonth}" class="next">next</button> </div> <div class="datepicker"> <table> <thead> <tr> <th each="{weekday,index in weekdays}">{weekday.substr(0,1)}</th> </tr> </thead> <tbody onclick="{setDay}"> <tr each="{week in weeks}" class="calweek"> <td each="{date in week}" val="{date.getTime()}" class="calday {getClass(date)}">{date.getDate()}</td> </tr> </tbody> </table> </div>', 'datepicker { display: inline-block; font-family: sans-serif; font-size: 12px; } datepicker button { border: 0px solid #000; background: #fff; } datepicker select { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); position: absolute; top: 0; left: 0; right: 0; } datepicker .monthpicker { position: relative; } datepicker .monthpicker span { position: relative; } datepicker .monthpicker div { text-align: center; line-height: 25px; } datepicker .next { position: absolute; top: 2px; right: 0px; } datepicker .prev { position: absolute; top: 2px; left: 0px; } datepicker td { padding: 5px; font-family: sans-serif; cursor: pointer; } datepicker .today { background: #eee; } datepicker .active { background: #f86; color: #fff; }', '', function(opts) {
var Calendar;

Calendar = require('../utils/calendar.coffee');

this.cal = new Calendar(opts.date);

this.months = this.cal.options.i18n.months;

this.weekdays = this.cal.options.i18n.weekdays;

this.years = this.cal.getYears();

this.days = this.cal.getDays();

this.weeks = this.cal.getWeeks();

this.selectedDate = new Date(this.cal.getDate());

if (opts.date) {
  this.selectedDate = opts.date;
}

this.previousUpdate = null;

this.on('mount', (function(_this) {
  return function() {
    if (opts.date != null) {
      _this.cal = new Calendar(opts.date);
      _this.selectedDate = _this.date = _this.cal.getDate();
      return _this.previousUpdate = _this.date.getTime();
    }
  };
})(this));

this.on('update', (function(_this) {
  return function() {
    var changetypes;
    changetypes = [];
    if (_this.month !== _this.cal.getMonth()) {
      _this.month = _this.cal.getMonth();
      changetypes.push('month');
    }
    if (_this.year !== _this.cal.getYear()) {
      _this.year = _this.cal.getYear();
      changetypes.push('year');
    }
    _this.weeks = _this.cal.getWeeks();
    if (_this.date && _this.cal.getDate() && _this.date.toString() !== _this.cal.getDate().toString()) {
      _this.cal = new Calendar(_this.date);
    }
    changetypes.push('date');
    if (opts.onupdate && changetypes.length > 0 && _this.date) {
      _this.date = _this.cal.getDate();
      if (_this.previousUpdate !== _this.date.getTime()) {
        _this.previousUpdate = _this.date.getTime();
        return opts.onupdate(_this.date, changetypes);
      }
    }
  };
})(this));

this.prevmonth = (function(_this) {
  return function() {
    return _this.cal.prevMonth();
  };
})(this);

this.nextmonth = (function(_this) {
  return function() {
    return _this.cal.nextMonth();
  };
})(this);

this.setMonth = (function(_this) {
  return function(e) {
    return _this.cal.setMonth(e.target.value);
  };
})(this);

this.setYear = (function(_this) {
  return function(e) {
    return _this.cal.setYear(e.target.value);
  };
})(this);

this.setDay = (function(_this) {
  return function(e) {
    var date;
    date = +e.target.getAttribute('val') || +e.target.parentNode.getAttribute('val');
    if ((date != null) && date > 0) {
      return _this.selectedDate = _this.date = new Date(date);
    }
  };
})(this);

this.getClass = (function(_this) {
  return function(date) {
    var classname, ref;
    classname = '';
    if (date.getMonth() !== _this.month) {
      classname += 'subtle ';
    }
    if (+(new Date().setHours(0, 0, 0, 0)) === date.getTime()) {
      classname += 'today ';
    }
    if (((ref = _this.selectedDate) != null ? ref.getTime() : void 0) === date.getTime()) {
      classname += 'active ';
    }
    if (opts.datestyle) {
      classname += opts.datestyle(date);
    }
    return classname;
  };
})(this);
});