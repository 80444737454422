module.exports = 

class DateFormat

  @i18n:
    months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
    weekdays: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

  constructor:(@date)->
    @date = new Date() if !@date? || !(@date instanceof Date)
    @i18n = DateFormat.i18n

  pad:(val,length=2)-> ("000000" + val).substr(-length,length)

  yyyy:-> @date.getFullYear().toString()

  yy:-> @yyyy().substr(2,2)

  M:-> ""+(@date.getMonth()+1)

  MM:-> @pad(@M())

  MMMM:-> DateFormat.i18n.months[@date.getMonth()]

  MMM:-> @MMMM().substr(0,3)

  d:-> ""+@date.getDate()

  dd:-> ""+@pad(@d())

  EEEE:-> DateFormat.i18n.weekdays[@date.getDay()]

  EEE:-> @EEEE().substr(0,3)

  H:-> ""+@date.getHours()

  HH:-> @pad(@H())

  h:-> if (h = @H()) < 13 then h else ""+(h-12)

  hh:-> @pad(@h())

  m:-> ""+@date.getMinutes()

  mm:-> ""+@pad(@m())
 
  s:-> ""+@date.getSeconds()

  ss:-> ""+@pad(@s())

  a:-> if @H() < 13 then "AM" else "PM"

  z:-> Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "(Local Time)"

  format:(format)-> format.replace /([a-z])+/ig,(val)=> if@[val] then @[val]() else val
  
  parseString:(format,str)-> DateFormat.parseString(format, str)

  @parseString:(format,str)->
    try
      matchers = {
        'yyyy':{reg:'([0-9]{4})',param:0}
        'yy':{reg:'([0-9]{2})',param:0}
        'M':{reg:'([0-9]{1,2})',param:1}
        'MM':{reg:'([0-9]{1,2})',param:1}
        'MMM':{reg:'([a-z]{3})',param:1}
        'MMMM':{reg:'([a-z]+)',param:1}
        'dd':{reg:'([0-9]{1,2})',param:2}
        'dd':{reg:'([0-9]{1,2})',param:2}
        'd':{reg:'([0-9]{1,2})',param:2}
      }
      counter = 0
      datePart = []
      pattern = format.replace /([a-z])+/ig,(val)=>
        if matchers[val]
          datePart[counter] = val
          counter++
          return matchers[val].reg 
        return val
      regex = new RegExp(pattern,"i")
      vals = str.match(regex)
      params = []
      datePart.forEach (val,idx)-> params[matchers[val].param] = vals[idx+1]
      if(params[1]-1>-1)
        params[1] = params[1]-1 if params[1] #substract 1 from month
      else
        #lookup txt month
        params[1] = DateFormat.i18n.months.indexOf((DateFormat.i18n.months.filter (month)-> params[1].substr(0,3) == month.substr(0,3))[0])
      #treat all years under 90 as 2090
      params[0] = "20"+params[0] if(params[0] < 90)
      return new Date(params[0],params[1],params[2])
    catch e
      return false