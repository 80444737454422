
riot.tag2('offer', '<forminput name="offer_code" label="Offer code?" onupdate="{handleChange}" value="{code}" buttonafter="Apply" buttonafterclick="{applyOffer}"></forminput> <div class="row"> <div class="col-sm-8 col-sm-offset-4"> <div if="{accepted}" class="alert alert-success"> <p riot-tag="i18n">Offer accepted</p> </div> <div if="{rejected}" class="alert alert-danger"> <p riot-tag="i18n">Offer not valid</p> </div> </div> </div>', '', '', function(opts) {
var offerApi, resource;

resource = require('../../utils/apiResource.coffee');

offerApi = new resource(opts.offersrc || '/duocms/api/offers/apply', true);

this.on('mount', function() {
  return this.code = "";
});

this.handleChange = (function(_this) {
  return function(change) {
    _this.code = change.value;
    return _this.update();
  };
})(this);

this.applyOffer = (function(_this) {
  return function(change) {
    return offerApi.post({
      code: _this.code
    }, function(data, err) {
      _this.accepted = false;
      _this.rejected = false;
      if (_this.timeout) {
        _this.timeout.clearTimeout();
      }
      _this.timeout = null;
      if (err != null) {
        _this.rejected = true;
        if (opts.offerRejected != null) {
          opts.offerRejected();
        }
      } else {
        _this.accepted = true;
        if (opts.offerAccepted != null) {
          opts.offerAccepted();
        }
      }
      _this.timeout = setTimeout(function() {
        _this.accepted = false;
        _this.rejected = false;
        _this.timeout = null;
        return _this.update();
      }, 1000);
      _this.code = "";
      return _this.update();
    });
  };
})(this);
});