module.exports =
 
 class Calendar 

  constructor:(date,options)->
    @date= date || new Date()
    @options =
      minDate:null
      maxDate:null
      yearRange:10
      i18n:
        previousMonth : 'Previous Month',
        nextMonth     : 'Next Month',
        months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
        weekdays      : ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
        weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']

  getDaysInMonth:(year, month)->
    [31,(if @isLeapYear(year) then 29 else 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]

  isLeapYear:(year)->
    year % 4 == 0 && year % 100 != 0 || year % 400 == 0

  isWeekend:(date)->
    day = date.getDay()
    day == 0 || day == 6

  isDate:(obj)->
    (/Date/).test(Object.prototype.toString.call(obj)) && !isNaN(obj.getTime())

  prevMonth:-> @date.setMonth(@getMonth()-1)

  nextMonth:-> @date.setMonth(@getMonth()+1)

  prevWeek:-> @date.setDate(@date.getDate()-7)

  nextWeek:-> @date.setDate(@date.getDate()+7)

  today:-> @date = new Date()

  getWeekdays:-> @options.i18n.weekdays

  getMonth:-> @date.getMonth()

  getYear:-> @date.getFullYear()

  getDays:->[0..@getDaysInMonth(@getYear(),@getMonth())]

  firstDay:-> new Date(@getYear(), @getMonth(), 0).getDay()-1

  getWeekOfMonth:->
    week = new Date(@getYear(),@getMonth(),0-@firstDay()-1)
    for index in [0..7]
      return index-2 if @date < week
      week = new Date(week.setDate(week.getDate()+7))

  getYears:->
    [-@options.yearRange..@options.yearRange].map (year)=> 
      @date.getFullYear()+year

  getWeeks:->
    year = @getYear()
    month = @getMonth()
    prevMonth = new Date(year,month,0,0,0,0)
    prevMonthLength = @getDaysInMonth(prevMonth.getFullYear(),prevMonth.getMonth())
    total = @getDaysInMonth(year,month)

    gridStart = new Date(year, month,0-@firstDay()-1)
    daycount = 0
    nextmonth = 0
    [0..5].map (weeknum)->
      [0..6].map (daynum)->
        gridStart = new Date(gridStart.getFullYear(),gridStart.getMonth(),gridStart.getDate()+1,0,0,0,0)

  getDate:-> @date

  setDay:(day)-> @date.setDate(day)

  setMonth:(month)-> @date.setMonth(month)

  setYear:(year)-> @date.setYear(year)

  repeat:(start,end,repeat='daily')->
    dt = new Date(start)
    dates = []
    if repeat == "daily"
      inc = (dt)-> new Date(dt.getTime()+24*60*60*1000)
    if repeat == "weekly"
      inc = (dt)-> new Date(dt.getTime()+7*24*60*60*1000)
    if repeat == "fortnightly"
      inc = (dt)-> new Date(dt.getTime()+14*24*60*60*1000)
    if repeat == "monthly"
      inc = (dt)-> new Date(new Date(dt).setMonth(dt.getMonth()+1))
    maxdays = 500
    return [start] if !inc
    while new Date(dt).setHours(0,0,0)<new Date(end).setHours(0,0,0)
      maxdays--
      return dates if maxdays<0
      dates.push dt
      dt = inc(dt)
    dates.push(end)
    return dates

  isToday:(compareDate)->
    return false if !compareDate
    am = compareDate.setHours(0,0)
    pm = compareDate.setHours(23,59)
    return if @date.getTime() >= am and @date.getTime() <= pm then true else false







      





