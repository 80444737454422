 require('../raw');
riot.tag2('currency', '<raw content="{symbol}{value}"></raw>', '', '', function(opts) {
this.on('update', (function(_this) {
  return function() {
    _this.value = _this.toFixed(opts.value);
    return _this.symbol = _this.symbolFromCode(opts.code);
  };
})(this));

this.symbolFromCode = function(code) {
  switch (code) {
    case 'EUR':
      return '&euro;';
    case 'USD':
      return '&dollar;';
    default:
      return '&pound;';
  }
};

this.toFixed = function(value) {
  var right;
  value = +value;
  if (isNaN(value)) {
    return 0/0;
  }
  value = value.toString().split('e');
  right = value[1] ? +value[1] + 2 : 2;
  value = Math.round(+(value[0] + 'e' + right));
  right = value[1] ? +value[1] - 2 : -2;
  value = value.toString().split('e');
  return (+(value[0] + 'e' + right)).toFixed(2);
};
});