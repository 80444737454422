
place = (opts,fix=false)->    
  srcpos   = opts.target.getBoundingClientRect()
  pos      = top:srcpos.top,left:srcpos.left
  pos.left = srcpos.left + srcpos.width   if opts.placement=="right"
  pos.left = pos.left - opts.element.offsetWidth  if opts.placement=="left"
  pos.top  = srcpos.top + srcpos.height   if opts.placement=="bottom"
  pos.top  = pos.top - opts.element.offsetHeight  if opts.placement=="top"

  if opts.placement.match(/bottom|top/i)
    pos.left = srcpos.left - (opts.element.offsetWidth / 2)+(opts.target.offsetWidth/2)#center
    pos.left = srcpos.left if(pos.left < 0)#align with left edge
    pos.left = srcpos.left - opts.element.offsetWidth + opts.target.offsetWidth if(pos.right < 0)#align with right edge
  if opts.placement.match(/left|right/i)
    pos.top = srcpos.top - (opts.element.offsetHeight / 2)+(opts.target.offsetHeight/2)#align middle
    pos.top = srcpos.top if(pos.top < 0)#align tops
    pos.top = srcpos.top - opts.element.offsetHeight + opts.target.offsetHeight if(pos.bottom < 0)#align bottoms 
  #set position, add offset to allow for scrolling
  opts.element.style.top=(pos.top+window.pageYOffset)+"px"
  opts.element.style.left=(pos.left+window.pageXOffset)+"px"

  newpos = opts.element.getBoundingClientRect()
  #flip sides if going offscreen, need to mod to allow for viewport
  if newpos.left < 0 || newpos.top < 0 || (newpos.left+newpos.width)>document.body.clientWidth || (newpos.top+newpos.height)>document.body.clientHeight
    switch opts.placement
      when "right" then opts.placement = "left"
      when "left" then opts.placement = "right"
      when "top" then opts.placement = "bottom"
      when "bottom" then opts.placement = "top"
    return place(opts,false) if fix
  return {top:(pos.top+window.pageYOffset),left:(pos.left+window.pageXOffset),placement:opts.placement}

module.exports = place