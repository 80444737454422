require('./popover');
riot.tag2('confirm', '<yield></yield> <popover title="{opts.title || \'Are You Sure ?\'}" placement="{opts.placement}"> <div class="row"> <div class="col-xs-6"> <button onclick="{hide}" class="btn btn-block btn-cancel btn-{parent.opts.cancelstyle||\'default\'}">{parent.opts.canceltext || \'Cancel\'}</button> </div> <div class="col-xs-6"> <button onclick="{parent.onconfirm}" class="btn btn-block btn-confirm btn-{parent.opts.confirmstyle||\'danger\'}">{parent.opts.confirmtext || \'Delete\'}</button> </div> </div> </popover>', '', '', function(opts) {
this.on('mount', function() {
  return this.root.addEventListener('click', this.handleClick);
});

this.on('unmount', function() {
  return this.root.removeEventListener('click', this.handleClick);
});

this.on('error', function(err) {
  return console.error(err);
});

this.handleClick = (function(_this) {
  return function(e) {
    if (opts.__disabled === true) {
      return;
    }
    return _this.tags.popover.clicked(e);
  };
})(this);

this.onconfirm = (function(_this) {
  return function() {
    var ref;
    if (opts.onconfirm != null) {
      opts.onconfirm();
    }
    return (ref = _this.tags.popover) != null ? ref.hide() : void 0;
  };
})(this);
});