 require('../modal');require('./basket');
riot.tag2('popup-basket', '<yield></yield> <modal name="basketmodal" bssize="lg" onclose="{opts.onclose || true}" title="Your Basket" onsave="{checkout}" savetext="Proceed to Checkout"> <basket riot-src="{parent.opts.src}" language="{parent.opts.language}" currency="{parent.opts.currency}" showvat="{parent.opts.showvat}"></basket> </modal>', '', 'onclick="{updateBasket}"', function(opts) {
this.on('mount', function() {
  return this.root.trigger = this.trigger;
});

this.on('updateBasket', function() {
  return this.updateBasket();
});

this.checkout = (function(_this) {
  return function() {
    return window.location.href = opts.checkoutUrl || "/checkout/";
  };
})(this);

this.updateBasket = (function(_this) {
  return function() {
    _this.tags.basketmodal.show();
    _this.tags.basketmodal.tags.basket.trigger('updateBasket');
    return _this.update();
  };
})(this);
});