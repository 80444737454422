
riot.tag2('address-search', '<div if="{showAddressSearch}"> <forminput label="Postcode Lookup" onupdate="{handleUpdate}" value="{postcode}" buttonafter="Search" buttonafterclick="{search}"></forminput> <formselect label="Select Address" options="{selectAddresses}" if="{selectAddresses.length &gt; 0}" onupdate="{selectAddress}" default="Select Address"></formselect> </div>', 'address-search .error { color: #a94442; }', '', function(opts) {
var request;

request = require('superagent');

this.postcode = '';

this.selectAddresses = [];

this.on('mount', function() {
  this.postcode = opts.postcode;
  this.checkAvailability();
  return this.update();
});

this.handleUpdate = (function(_this) {
  return function(input) {
    _this.postcode = input.value;
    return _this.update();
  };
})(this);

this.checkAvailability = (function(_this) {
  return function() {
    return request.get('https://api.ideal-postcodes.co.uk/v1/keys/' + _this.opts.key, function(err, res) {
      var ref, ref1, ref2, ref3;
      if (err != null) {
        _this.showAddressSearch = false;
        if (_this.opts.onError != null) {
          return _this.opts.onError(err);
        }
      } else {
        if (!(res != null ? (ref = res.body) != null ? (ref1 = ref.result) != null ? ref1.available : void 0 : void 0 : void 0)) {
          if (_this.opts.onError != null) {
            _this.opts.onError(err);
          }
        } else {
          _this.showAddressSearch = res != null ? (ref2 = res.body) != null ? (ref3 = ref2.result) != null ? ref3.available : void 0 : void 0 : void 0;
        }
        return _this.update();
      }
    });
  };
})(this);

this.search = (function(_this) {
  return function() {
    return request.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + _this.postcode, {
      api_key: _this.opts.key
    }, function(err, res) {
      var ref;
      if (err != null) {
        _this.selectAddresses = [];
        if (_this.opts.onError != null) {
          _this.opts.onError(err);
        }
      } else {
        _this.addresses = res.body.result;
        _this.selectAddresses = (ref = _this.addresses) != null ? ref.map(function(address, i) {
          return {
            label: address.line_1 + ' ' + address.line_2,
            value: i
          };
        }) : void 0;
      }
      return _this.update();
    });
  };
})(this);

this.selectAddress = (function(_this) {
  return function(option) {
    if ((_this.opts.onAddressSelected != null) && (option != null)) {
      return _this.opts.onAddressSelected(_this.addresses[option.value]);
    }
  };
})(this);
});