module.exports = 

  responseConvert:(data)->
    # returns on empty results
    return data if !data 

    # works with records and collections
    if(data.constructor == Array) 
      for row,key in data
        data[key]=@responseConvert(row)
      return data

    mysqlDateFormat = /^([0-9]{4}-[0-9]{2}-[0-9]{2}|[0-9]{4}-[0-9]{1,2}-[0-9]{1,2} [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2})$/
    jsDateFormat = /^([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z)$/
    for key,value of data
      if typeof value == "string" && (match = value.match(mysqlDateFormat))
        data[key] = @stringToDate(match[0])
      else if typeof value == "string" && (match = value.match(jsDateFormat))
        data[key] = new Date(match[0])
      else if (typeof value == "object")
        @responseConvert(value) #recurse into nest object
    return data

  stringToDate:(value)->
    parts = value.split(/[^0-9]/)
    if(value=="0000-00-00 00:00:00" || value=="0000-00-00")
      return ''
    if(parts.length>5)
      return new Date(parts[0],parts[1]-1,parts[2],parts[3],parts[4],parts[5] )
    if(parts.length>2)
      return new Date(parts[0],parts[1]-1,parts[2])
    return value

  requestConvert:(data)->
    # returns on empty results
    return data #if !data --disabled here for js version

    # works with records and collections
    if(data.constructor == Array) 
      for row,key in data
        data[key]=@requestConvert(row)
      return data    
    
    newobj = {}
    for key,value of data
      if value instanceof Date
        d = value
        newobj[key] = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
      else if (typeof value == "object")
        @requestConvert(value) #recurse into nest object
      else
        newobj[key] = value
    return newobj