module.exports = (request,stores,resourceUtils)->

  class Resource 

    constructor:(url,@returnErrors=false)->
      @resourceUrl = url
      request.Request.prototype.csrf = (token)->
        token = window.csrf if (!token)
        @set('X-CSRF-Token', token);
        return this

    get:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set') 
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      request.get url,params[0],(err,res)=> 
        @createResponse(err,res,params,cback,'get')
    
    post:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set')
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      request.post(url).send(params[0]).csrf().end (err,res)=>
        @createResponse(err,res,params,cback,'post')

    save:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set')
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      if(params[0]?.id)
        request.put(url).send(params[0]).csrf().end (err,res)=> 
          @createResponse(err,res,params,cback,'save')
      else
        request.post(url).send(params[0]).csrf().end (err,res)=>
          @createResponse(err,res,params,cback,'save')

    remove:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set')
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      request.del(url).send(params[0]).csrf().end (err,res)=> 
        @createResponse(err,res,params,cback,'remove')

    createResponse:(err,res,params,cback,method)->
      data = resourceUtils.responseConvert(res?.body?.data)
      cback(data) if !@returnErrors && cback?
      err = res.body if err
      cback(data, err) if @returnErrors && cback?