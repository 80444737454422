module.exports = 
  class i18n 
    DEFAULT_LANG = 'en'

    constructor:()->
      @_entities = {}
      @_defaultLang = DEFAULT_LANG
      @_language = @_defaultLang
      @i18n = riot.observable()
      @i18n.on('lang', @setLanguage)

      @i18n.localise = @localise
      @i18n.addLanguage = @addLanguage
      @i18n.setLanguage = @setLanguage

    localise: (key)=>      
      return key if !@_entities[@_language]?[key]
      @_entities[@_language][key]
      
    setLanguage: (lang) ->
      @_language = lang or @_defaultLang
      @i18n.trigger 'update'

    addLanguage: (code, lang)->
      @_entities[code] = lang